$assetsPath: '//cdn.switchfly.com/static/4c72c35/resources/shopping/images/themes/aavacations';

// Imports and mixins
// -------------------------

@import "_webfonts";

// Client Colors
// -------------------------

$aa-dark-blue: #00467f;
$aa-blue: #0078d2;
$aa-light-blue: #0579C7;
$aa-teal: #b1e1eb;
$aa-highlight-blue: #effafc;
$aa-green: #00b989;
$aa-yellow-green: #d1d532;
$aa-orange: #d14904;
$aa-light-orange: #faaf00;
$aa-dark-orange: #953403;
$aa-dark-red: #c30019;
$aa-red: #f52305;
$aa-black: #131313;
$aa-dark-gray: #36495a;
$aa-medium-gray: #6e8999;
$aa-gray: #495057;
$aa-light-gray: #d0dae0;
$aa-ultra-light-gray: #eee;
$aa-darker-blue: #0066b4; //darker blue used on light backgrounds
$aa-a11y-blue: #0061AB;

// Grays
// -------------------------

$gray-dark: $aa-dark-gray;
$gray: $aa-gray;
$gray-light: $aa-light-gray;
$gray-lighter: $aa-ultra-light-gray;

// Brand colors
// -------------------------

$brand-primary: $aa-blue;
$brand-warning: $aa-orange;
$brand-danger: $aa-dark-red;
$brand-info: $aa-light-blue;
$brand-success: $brand-info;
$brand-featured: $brand-primary;

$state-success-text: $aa-blue;
$alert-success-text: #396f3a; // app-theme's success text color

// Links
// -------------------------

$link-color: $brand-primary;
$link-hover-color: $brand-info;
$aside-contents-section-link-color: $aa-dark-blue;
$aside-contents-section-link-hover-color: darken($aa-dark-blue, 5%);

.room-selected a {
  color: $aside-contents-section-link-color;
  &:hover, &:focus, &:active {
    color: $aside-contents-section-link-hover-color;
  }
}

// Typography
// -------------------------

$aa-webfonts-regular: 'AmericanSansRegular', Calibri, Helvetica, Arial, sans-serif;
$aa-webfonts-medium: 'AmericanSansMedium', Calibri, Helvetica, Arial, sans-serif;
$aa-webfonts-light: 'AmericanSansLight', Calibri, Helvetica, Arial, sans-serif;

$font-family-sans-serif: $aa-webfonts-regular;
$font-family-base: $font-family-sans-serif;

$font-size-base: 14px;
$font-size-h1: 42px;
$font-size-h2: 27px;
$font-size-h3: 21px;
$font-size-h4: 18px;
$font-size-h5: 14px;
$font-size-h6: 11px;

$line-height-computed: 18px;

$headings-font-family: $aa-webfonts-light;
$headings-font-weight: normal;
$headings-line-height: 1.2;

// Buttons
// -------------------------

$btn-border-radius: 0;
$btn-border-width: 0;
$btn-font-size: 13px;
$btn-font-family: $aa-webfonts-medium;

$btn-primary-bg-start: $brand-primary;
$btn-primary-bg-end: $aa-dark-blue;

$btn-secondary-bg-start: $aa-light-blue;

$btn-default-color: #fff;
$btn-default-bg: $aa-blue;
$btn-default-bg-end: $aa-blue;
$btn-default-bg-start-hover: darken($aa-blue, 10%);
$btn-default-bg-end-hover: $btn-default-bg-start-hover;

@include gradient-buttons;

// Deals
// -------------------------

$rate-deal-bg-color: $gray-light;
$product-deal-client-approved-bg-color: $aa-blue;

// Global
// -------------------------

$site-header-background: #fff;
$site-header-logo-primary-file-path: "#{$assetsPath}/aav-logo.png";

$site-header-brand-container-col-width-tablet: 6;
$site-header-loyalty-container-col-width-tablet: 6;

$site-header-brand-container-col-width-handheld: 12;
$site-header-loyalty-container-col-width-handheld: 12;

// Helper classes
// -------------------------

$price-text-color: $gray-dark;
$points-text-color: $brand-info;

$removable-text-color: $aa-dark-gray;

$product-body-bg-color-selected: $aa-highlight-blue;
$product-body-bg-color-selected_handheld: $product-body-bg-color-selected;
$product-border-color-selected: darken($product-body-bg-color-selected, 20%);
$product-border-color-selected_handheld: $product-border-color-selected;
$product-detail-drawer-bg-color: $gray-lighter;

// Page Level Styles
// -------------------------

$section-header-text-color: $brand-primary;
$section-header-border-bottom-color: $gray-lighter;

//resolves a11y contrast issues
$subheader-toolbar-row-background-color_handheld: #fff;
$section-header-background-color_handheld: #fff;
$price-table-bg-color_handheld: #fff;

// Page Aside Contents Container
// --------------------------

$aside-contents-section-top-header-background-color: $brand-primary;
$aside-contents-section-top-header-text-color: $site-header-background;

// Points Slider Component
// -------------------------

$points-slider-selection-background-color: $brand-info;
$points-slider-handle-text-color: $points-slider-selection-background-color;

// Forms
// -------------------------
$select-info-bg-color: $aa-highlight-blue;
$select-info-text-color: $aa-darker-blue;

// Confirmation
// -------------------------
$confirmation-section-body-bg: #fff;
$confirmation-section-margin-vertical: 0;

// Flight Matrix
// -------------------------
$product-group_0--feature-color: $aa-light-gray;
$product-group_1--feature-color: $aa-a11y-blue;
$product-group_2--feature-color: $aa-darker-blue;
$product-group_3--feature-color: $aa-dark-blue;
$product-group_4--feature-color: $aa-dark-blue;
$product-summary-link-color: $aa-dark-blue;
$product-summary-link-color-hover: $aa-dark-blue;

// Custom CSS
// -------------------------

#site-header .header-brand-container .logo-primary {
  background: none;

  &:before {
    content: url($site-header-logo-primary-file-path);
    position: relative;
  }
}

@media (max-width: $screen-xs) {
  #site-header {
    .header-brand-container .logo-primary:before {
      transform: scale(.75);
      display: block;
      left: -40px;
    }
  }
}

@mixin aa-header-logo($logoName) {
  #site-header .header-brand-container .logo-primary:before {
    content: url('#{$assetsPath}/#{$logoName}');
    position: relative;
  }
}

.cobrand-AADVburn,
.cobrand-AADVburnEN,
.cobrand-AADVburnPO,
.cobrand-AADVburnSP {
  @include aa-header-logo('aadvburn-logo.png')
}

#site-header {
  > .container {
    padding: 20px 8px;
  }

  .loyalty-summary.form-inline {
    margin-top: 25px;

    .btn-info {
      @extend .btn-link;
      background: none;
    }
  }
}

.page-content {
  h1 {
    color: $brand-primary;
  }

  h3 {
    color: $gray-dark;
  }

  h4 {
    color: $gray;
    font-family: $aa-webfonts-regular;
  }

  h5, h6 {
    color: $gray;
    font-family: Arial, Helvetica, sans-serif;
  }

  .btn-primary {
    text-shadow: 0 1px 0 $link-color;
  }

  .btn-primary:hover {
    @include gradient-vertical($aa-dark-blue, $brand-primary);
    background-position: 0 0;
  }

}

.aside-contents-container {
  .summary-components {
    background: $brand-primary;
    color: $site-header-background;
    margin: -10px -5.5% 10px -5.5%;
    padding: 10px 5%;

    .text-muted {
      color: $site-header-background;
    }
  }

  .text-warning {
    color: $aa-dark-orange;
  }
}

.product-promo-container i {
  display: none;
}

#hotel-detail {
  .price-container .price-total + li {
    font-weight: bold;
    font-size: 16px;
  }
}

#pricing-summary .fine-print {
  display: none;
}

#your-itinerary .air-segment div:first-of-type {
  margin-bottom: 10px;
}

#checkout-form-container {
  .removable {
    i {
      color: $brand-danger;
    }
  }

  .sec-code {
    background: none !important;

    &:before {
      content: url('/resources/shopping/images/application/cvv2/security_code.png');
      position: relative;
    }
  }
}

.products-list .product-selected {
  .btn-link,
  .text-success,
  a {
    color: $aa-darker-blue;
  }

  .text-warning {
    color: $aa-dark-orange;
  }
}

.products-list {
  .product-body {
    .tag-success {
      color: $aa-blue;
      background-color: white;
    }
  }
}

.loyalty-summary .btn-default {
  @include gradient-vertical($aa-dark-blue, $aa-blue);
  border-radius: 3px;
  font-family: $aa-webfonts-regular;
}

// Resolves a11y contrast issues
body.case-handheld {
  .btn-link {
    color: $aa-a11y-blue;
  }

  .page-body .page-header-toolbar .container:nth-child(2) {
    border-top: 1px solid $aa-light-gray;
    border-bottom: 1px solid $aa-light-gray;
  }

  #checkout-form-container #pricing-summary .table-container {
    box-shadow: 0 0 3px rgba(0,0,0,.25);
  }

  .air-leg-journey-summary {
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

#dashcart #trip-total-price {
  font-size: $font-size-base + 2;
}

.products-list .product-body .price-saving span {
    color: #008712;
    font-size: 15px;
    font-weight: bold;
}

.radio {
  background: inherit !important;
  font-size: inherit !important;
}

// IMPORTANT: Must be loaded LAST

@import "_responsive";